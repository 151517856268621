import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MainSection = () => {
  const data = useStaticQuery(graphql`
    query {
      serviceParking: file(
        relativePath: { eq: "products/service-parking.svg" }
      ) {
        publicURL
      }
      serviceTag: file(relativePath: { eq: "products/service-tag.svg" }) {
        publicURL
      }
      serviceValue: file(relativePath: { eq: "products/service-value.svg" }) {
        publicURL
      }
      serviceFines: file(relativePath: { eq: "products/service-fines.svg" }) {
        publicURL
      }
      serviceTrafficRestriction: file(
        relativePath: { eq: "products/service-traffic-restriction.svg" }
      ) {
        publicURL
      }
      serviceMallParking: file(
        relativePath: { eq: "products/service-mall-parking.svg" }
      ) {
        publicURL
      }
      serviceAsure: file(relativePath: { eq: "products/service-asure.svg" }) {
        publicURL
      }
      serviceDealership: file(
        relativePath: { eq: "products/service-dealership.svg" }
      ) {
        publicURL
      }
      serviceFuel: file(relativePath: { eq: "products/service-fuel.svg" }) {
        publicURL
      }
      serviceMaintenance: file(
        relativePath: { eq: "products/service-maintenance.svg" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-main">
      <div className="mockup"></div>
      <div className="container">
        <h1 className="title">
          <span className="has-text-blue">
            Seu <br className="is-hidden-desktop" />
            carro
          </span>{" "}
          <br className="is-hidden-mobile" />
          <span className="has-text-white">mais completo</span>
        </h1>
        <p className="subtitle">
          Encontre todos os <br className="is-hidden-desktop" />
          serviços úteis para <br className="is-hidden-desktop" />o seu veículo,{" "}
          <br className="is-hidden-desktop" />
          em um só aplicativo:
        </p>
        <div className="is-clearfix" style={{ "padding-top": "80px" }}>
          <ul className="services-list">
            <li className="service">
              <img
                className="icon"
                src={data.serviceParking.publicURL}
                alt="Estacionamento Rotativo"
              />
              <p className="title">
                Estacionamento <br />
                Rotativo
              </p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceTag.publicURL}
                alt="Tag de pedágio"
              />
              <p className="title">
                Tag de <br />
                pedágio
              </p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceFines.publicURL}
                alt="Multas, IPVA & Licenciamento"
              />
              <p className="title">Multas, IPVA & Licenciamento</p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceFuel.publicURL}
                alt="Combustível"
              />
              <p className="title">Combustível</p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceValue.publicURL}
                alt="Valor de mercado"
              />
              <p className="title">
                Valor de <br />
                mercado
              </p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceTrafficRestriction.publicURL}
                alt="Rodízio de veículos"
              />
              <p className="title">
                Rodízio de <br />
                veículos
              </p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceMaintenance.publicURL}
                alt="Manutenção"
              />
              <p className="title">Manutenção</p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceDealership.publicURL}
                alt="Concessionárias próximas"
              />
              <p className="title">
                Concessionárias <br />
                próximas
              </p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceAsure.publicURL}
                alt="Seguro Auto"
              />
              <p className="title">Seguro Auto</p>
            </li>
            <li className="service">
              <img
                className="icon"
                src={data.serviceMallParking.publicURL}
                alt="Estacionamento de shopping"
              />
              <p className="title">
                Estacionamento <br />
                de shopping
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default MainSection
